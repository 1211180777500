import { CSpinner } from "@coreui/react-pro";

export const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <CSpinner variant="grow" color="primary"></CSpinner>
      </div>
    </div>
  );
};